














































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";

const CostBearerDocumentModule = namespace("cost-bearer-document");

@Component({
  components: { FscModal, EditButton, AbortButton, FscSimpleCard, SaveButton },
})
export default class CostBearerDepositInvoiceDocumentPreview extends Vue {
  public name = "CostBearerDepositInvoiceDocumentPreview";

  @CostBearerDocumentModule.Action("generateCostBearerInvoicePreview")
  public generateInvoicePreview: any;

  @CostBearerDocumentModule.Getter("getMimeType")
  public mimeType: any;

  @CostBearerDocumentModule.Getter("getDataItem")
  public blob: any;

  @CostBearerDocumentModule.Getter("getIsLoading")
  public isLoading: any;

  @Prop()
  public studentEducationId!: any;

  @Prop()
  public invoiceData!: any;

   // start Draft Text
  public includeDraftText = true;
  private includeDraftTextPreviousState = true;
  public includeDraftTextModalConfirmationId = "cost-bearer-deposit-invoice-document-with-draft-text";
  // end Draft Text

  private source = "";

  public mounted(): void {
    if (this.studentEducationId) {
      const options = {
        id: this.studentEducationId,
        data: this.invoiceData,
        includeDraftText: this.includeDraftText
      };
      this.generateInvoicePreview(options);
    }
  }

  @Watch("blob")
  public onStudentDocumentChange(blob: any) {
    if (blob) {
      this.source =
        "data:" +
        this.mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    }
  }

  @Watch("studentEducationId")
  public onItemChange(id: any): void {
    if (id) {
      const options = {
        id: id,
        data: this.invoiceData,
        includeDraftText: this.includeDraftText
      };
      this.generateInvoicePreview(options);
    }
  }

  protected ok(): void {
    this.$emit("ok", this.includeDraftText);
  }

  protected cancel(): void {
    this.$emit("cancel");
  }

  // start Draft Text
  private changePdf() {
    const options = {
      id: this.studentEducationId,
      data: this.invoiceData,
      includeDraftText: this.includeDraftText
    };
    this.generateInvoicePreview(options);
  }

  public onIncludeDraftTextAbort(): void {
    this.includeDraftText = true; // Reset the modalShown flag
    this.$bvModal.hide(this.includeDraftTextModalConfirmationId);
  }

  public onIncludeDraftTextSave(): void {
    this.includeDraftText = this.includeDraftTextPreviousState;
    this.$bvModal.hide(this.includeDraftTextModalConfirmationId);
    this.changePdf();
  }

  public onIncludeDraftTextChanged(e: any) {

    if (!this.includeDraftText) {
      this.includeDraftTextPreviousState = this.includeDraftText;
      // Prevent the switch state change until modal confirmation
      this.includeDraftText = !this.includeDraftText;
      // Show the modal
      this.$bvModal.show(this.includeDraftTextModalConfirmationId);
      
    } else {
      this.changePdf();
    }
  }
  // end Draft Text

}
